import React from "react"
import ServicesScreen from "@screens/services/services"
import { Flats } from "@screens/services/components"

const Services = () => (
  <ServicesScreen>
    <Flats />
  </ServicesScreen>
)

export default Services
